import styles from './Main.module.css';
import Profile from '../../assets/profile.svg';
import Logo from '../../assets/logo_5.png';
import { MdMailOutline } from 'react-icons/md';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-scroll';

const Main = () => {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <img className={styles.logo} src={Logo} alt="Logo" />
        <div className={styles.menu}>
          <button className={styles.headerButton}>Aplikacja</button>
          <Link
            activeClass="active"
            to="secondary"
            spy={true}
            smooth={true}
            duration={800}
          >
            <button className={styles.headerButton}>Co zyskasz?</button>
          </Link>
          {/* <button className={styles.headerButton}>Oferta</button> */}
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={800}
          >
            <button className={styles.headerButton}>Kontakt</button>
          </Link>
        </div>
        <div className={styles.headerMail}>
          <MdMailOutline className={styles.mailIcon} />
          <a href="mailto:oferta@view24.pl">oferta@view24.pl</a>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.textBanner}>
          <div className={styles.title}>
            Kontrola nad Twoimi
            <Typewriter
              options={{
                strings: [
                  'danymi',
                  'upoważnieniami',
                  'szkoleniami',
                  'certyfikatami',
                ],
                autoStart: true,
                loop: true,
              }}
            />
            dzięki
            <span className={styles.secondaryColor}> VIEW24</span>
          </div>
          <div className={styles.subText}>
            Twoje dane są bezcenne, dlatego warto zadbać o ich ochronę.{' '}
            <span className={styles.secondaryColor}>VIEW24 </span>
            to kompleksowe rozwiązanie, które pozwoli Ci na skuteczne
            zarządzanie danymi osobowymi, zgodnie z obowiązującymi przepisami.
            Zapomnij o stresie i niepewności związanym z RODO - skorzystaj z
            naszej aplikacji już dziś!
          </div>
        </div>
        <div className={styles.imageBanner}>
          <img className={styles.image} src={Profile} alt="View24" />
        </div>
      </div>
    </div>
  );
};

export default Main;
