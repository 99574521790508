import styles from './App.module.css';
import Main from './containers/Main/Main';
import Secondary from './containers/Secondary/Secondary';
import Contact from './containers/Contact/Contact';
import { Element } from 'react-scroll';

function App() {
  return (
    <div id="page-wrap" className={styles.App}>
      <Main id="main" />
      <Element name="secondary">
        <Secondary />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
    </div>
  );
}

export default App;
