import styles from './Contact.module.css';
import Mail from '../../assets/mail.svg';
import Logo from '../../assets/logo_rodo.webp';
import { MdMailOutline, MdPhone } from 'react-icons/md';

const Contact = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.textBanner}>
          <div data-aos="fade-right" className={styles.title}>
            Skontaktuj się z nami!
          </div>
          <div data-aos="fade-right" className={styles.subText}>
            Poznaj możliwości aplikacji{' '}
            <span className={styles.secondaryColor}>VIEW24</span>
            <div data-aos="fade-right" className={styles.contactList}>
              <div className={styles.contactItem}>
                <MdPhone className={styles.icon} />
                <a href="tel:605044905">605 044 905</a>
              </div>
              <div className={styles.contactItem}>
                <MdPhone className={styles.icon} />
                <a href="tel:509793242">509 793 242</a>
              </div>
              <div className={styles.contactItem}>
                <MdMailOutline className={styles.icon} />
                <a href="mailto:oferta@view24.pl">oferta@view24.pl</a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-left" className={styles.imageBanner}>
          <img className={styles.image} src={Mail} alt="View24" />
        </div>
      </div>
      <div className={styles.footer}>
        <a href="https://www.rodo.pl">
          <img className={styles.logo} src={Logo} alt="rodo.pl" />
        </a>
      </div>
    </div>
  );
};

export default Contact;
