import styles from './Secondary.module.css';
import Security from '../../assets/security.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Secondary = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div data-aos="fade-right" className={styles.imageBanner}>
          <img className={styles.image} src={Security} alt="View24" />
        </div>
        <div className={styles.textBanner}>
          <div data-aos="fade-right" className={styles.title}>
            Dzięki <span className={styles.secondaryColor}>VIEW24</span>{' '}
            zyskasz:
          </div>
          <div className={styles.subText}>
            <ul>
              <li data-aos="fade-left">
                Sprawne zarządzanie uprawnieniami do systemów i stref dostępu
              </li>
              <li data-aos="fade-left">Szkolenia pracowników</li>
              <li data-aos="fade-left">
                Generowanie upoważnień, certyfikatów, kart obiegowych,
                rejestrów.... a to dopiero początek!
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secondary;
